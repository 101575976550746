@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-size: 16px;
}

body {
  font-family: 'Mulish';
}

b, strong {
  font-weight: 700;
}

.size-14 {
  font-size: 14px;
}

.size-10 {
  font-size: 10px;
}

.text-blue {
  color: #132d52;
}

.text-white {
  color: white;
}

.text-capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.border-top {
  border-top: 1px solid transparent;
}

.border-bottom {
  border-bottom: 1px solid transparent;
}

.border-left {
  border-left: 1px solid transparent;
}

.border-right {
  border-right: 1px solid transparent;
}

.center {
  text-align: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-row, .ant-col, .ant-modal {
  font-family: 'Mulish';
}